import React from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";
import MapGoogle from "../components/MapGoogle";
import PageWrapper from "../components/PageWrapper";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box, Text } from "../components/Core";

const StyledButton = styled(Button)`
  padding: 0.42rem 0.875rem;
  min-width: 100px;
  background: #4caf50;
  border-color: #4caf50;
`;

const StyledBox = styled(Box)`
  background: rgb(234, 233, 242);
  border-color: #4caf50;
`;

export const ContactPageTemplate = ({ data }) => {
  return (
    <section className="section">
      <Section>
        <div className="pt-5"></div>
        <Container className="position-relative">
          <Row>
            <Col lg={6} className="mb-5 mb-lg-0">
              <div className="banner-content mb-4">
                <Title>{data.title}</Title>
                <Text>{data.subtitle}</Text>
              </div>
              <StyledBox
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center mb-4"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="primary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                >
                  <i className="fab fa-youtube"></i>
                </Box>
                <Text fontSize={2} lineHeight="1.25">
                  {data.tip1}
                  <a
                    href={
                      "https://youtube.com/playlist?list=PLZIpNugyudAdPS0Rk3S6g1pOe_RbyJnnH"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <u>{data.tip2}</u>{" "}
                  </a>
                  {data.tip3}
                </Text>
              </StyledBox>
              <div>
                <Box mb={4}>
                  <Title variant="card" fontSize="18px" as="label">
                    {data.emailtitle}
                  </Title>
                  <a href={"mailto:" + data.email}>
                    <Text>{data.email}</Text>
                  </a>
                </Box>
                <Box mb={0}>
                  <Title variant="card" fontSize="18px" as="label">
                    {data.phonetitle}
                  </Title>
                </Box>
                <Box mb={4} className="d-flex flex-wrap align-items-center">
                  <Text className="mr-4">{data.phone}</Text>
                  <a
                    href="https://wa.me/31202117109"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledButton size="s" type="submit" borderRadius={10}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        style={{ width: 32, marginRight: 5 }}
                      >
                        <path
                          id="whatsapp"
                          d="M24.39552,7.5552A11.84119,11.84119,0,0,0,5.76286,21.83936L4.08333,27.974l6.27577-1.64631a11.82686,11.82686,0,0,0,5.65732,1.44094h.00485A11.84352,11.84352,0,0,0,24.39552,7.5552Zm-8.37425,18.214h-.004a9.82365,9.82365,0,0,1-5.00812-1.37153l-.35931-.21316-3.72411.97686.99406-3.631-.234-.37238a9.83953,9.83953,0,1,1,8.33545,4.61117Zm5.39722-7.36933c-.29582-.14806-1.75008-.86356-2.02126-.96235s-.46835-.14806-.66551.14806-.76405.96247-.93669,1.15981c-.17246.19734-.345.22215-.64081.07409a8.08074,8.08074,0,0,1-2.37877-1.46816,8.91445,8.91445,0,0,1-1.6456-2.04926c-.17252-.296-.01834-.45618.12972-.60364.13308-.1326.29583-.34552.44371-.51828a2.01944,2.01944,0,0,0,.29577-.49334.54473.54473,0,0,0-.02464-.51828c-.074-.14807-.6655-1.60412-.912-2.19649-.24013-.57666-.484-.4985-.66556-.50773-.17234-.00851-.36974-.01043-.56689-.01043a1.08675,1.08675,0,0,0-.78881.37022,3.31746,3.31746,0,0,0-1.0353,2.46767,5.75316,5.75316,0,0,0,1.20782,3.05993,13.18546,13.18546,0,0,0,5.05314,4.46658,17.00685,17.00685,0,0,0,1.68629.62306,4.05344,4.05344,0,0,0,1.8632.11713,3.04616,3.04616,0,0,0,1.99657-1.40641,2.47268,2.47268,0,0,0,.17252-1.40666C21.91148,18.622,21.71426,18.54805,21.41849,18.39987Z"
                          fill="#fff"
                        />
                      </svg>
                      {data.startchat}
                    </StyledButton>
                  </a>
                </Box>
                <Box mb={4}>
                  <Title variant="card" fontSize="18px" as="label">
                    {data.addresstitle}
                  </Title>
                  <Text>{data.address1}</Text>
                  <Text>{data.address2}</Text>
                  <Text>{data.address3}</Text>
                </Box>
                <Box mb={4}>
                  <Title variant="card" fontSize="18px" as="label">
                    {data.coctitle}
                  </Title>
                  <Text>{data.coc}</Text>
                </Box>
                <Box mb={4}>
                  <Title variant="card" fontSize="18px" as="label">
                    {data.vattitle}
                  </Title>
                  <Text>{data.vat}</Text>
                </Box>
                <Box mb={4}>
                  <Title variant="card" fontSize="18px" as="label">
                    {data.ibantitle}
                  </Title>
                  <Text>{data.iban}</Text>
                </Box>
              </div>
            </Col>
            <Col lg={8} className="position-static">
              <MapGoogle />
            </Col>
          </Row>
        </Container>
      </Section>
    </section>
  );
};

// TermsPageTemplate.propTypes = {
//   title: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   contentComponent: PropTypes.func,
// };

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <PageWrapper footerDark>
      <ContactPageTemplate data={post.frontmatter} />
    </PageWrapper>
  );
};

// TermsPage.propTypes = {
//   data: PropTypes.object.isRequired,
// };

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        tip1
        tip2
        tip3
        emailtitle
        email
        phonetitle
        phone
        whatsapp
        startchat
        addresstitle
        address1
        address2
        address3
        coctitle
        coc
        vattitle
        vat
        ibantitle
        iban
      }
    }
  }
`;
